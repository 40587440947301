* {
    font-family: Helvetica, Arial, sans-serif;
    color: white;
}

body {
    padding: 60px;
    background-color: #121212;
}

input {
    color: gray;
    font-size: 1.2rem;
    width: 100%;
}

.card {
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid gray;
    background-color: #2b2b2b;
    border-radius: 6px;
    overflow: hidden;
    margin: 5px 5px;
    padding: 20px 10px;
    max-width: 250px;
}

.card img {
    max-width: 200px;
}
.card p {
    align-self: flex-start;
    margin-bottom: 0px;
    color: grey;
}

.card-grid {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}
